
$header-height: 75px;
$header-background-color: rgb(113, 113, 113);
$header-background-color-light: lighten($header-background-color, 2%);
$header-background-color-top: rgb(113, 113, 113);
$header-background-color-bottom: rgb(113, 113, 113);
$submenu-background-color: darken($header-background-color, 5%);
//$header-background-color-top: rgb(123, 123, 123);
//$header-background-color-bottom: rgb(103, 103, 103);
$header-link-color: rgb(191, 189, 187);
$header-link-color-active: lighten(rgb(120, 120, 255),5%);

//TODO overwirte the link color using boostrap settings
a {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: rgb(200,200,200);
}

.lyt-header {
  position: fixed;
  right: 0px;
  top: 20px;
  min-width: 780px;
  left: 90px;
  //width: 80%;
  
  height: $header-height;
  z-index: 3;
  padding-right: 90px;
}





.lyt-header-inner {
  position: relative;
  left: 0px;
  top: 0px;
  margin: auto;
  max-width: 1000px;
}



.lyt-header-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: $header-background-color;
  background: linear-gradient(0deg, $header-background-color-bottom, $header-background-color-top);
  z-index: -1;
}

.lyt-header-bg-glow {
  position: absolute;
  left: 3px;
  top: 5px;
  width: 100%;
  height: 100%;
  z-index: -2;
  box-shadow: 2px 2px 15px rgba(0,0,255,1);
  
}

.lyt-main-menu {
  
  position: absolute;
  left: 280px;
  top: 0px;
  
  ul, li {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  
  a {
    color: $header-link-color;
    text-decoration: none;
    //transition: 0.3s color, 0.3s text-shadow;
  }
  
  a:hover, a.active {
    color: $header-link-color-active;
    text-decoration: none;
  }
}


.lty-primary-menu {
  
  float: left;
  
  a {
    cursor: pointer;
  }
  
  .menu-item {
    position: relative;
    left: 0px;
    top: 0px;
 
    
    .menu-item-inner {
      font-size: 16px;
      font-weight: bold;

      span {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
      }
      
    }
    
    .menu-item-inner:hover, .menu-item-inner.active {
      text-shadow: 0px -1px 1px rgba(0,0,255,0.9);
    }
    
   
  
    float: left;
    display: block;
  
    line-height: $header-height;

    
    .sub-menu {
      border-top: 1px solid rgb(39, 39, 39);
      display: none;
      position: absolute;
      top: $header-height * 2;
      left: 0px;
      line-height: 1.2em;
      background-color: $submenu-background-color;
      z-index: -1;
      
      li {
        padding: 3px;
        padding-left: 9px;
        padding-right: 9px;
      }
    }
    
  }
  
}

.no-js, .lagacy-menu {
  .menu-item:hover {
    background-color: $header-background-color-light;
    .menu-item-inner {
      display: block;
      margin-top: $header-height;
    
      background-color: $header-background-color;
      background: linear-gradient(0deg, $header-background-color-bottom, $header-background-color-top);
    }
    
    .sub-menu {
      display: block;
    }
  }
}




.advanced-menu {
  .hover {
    
    background-color: $header-background-color-light;
    .menu-item-inner {
      display: block;
      //margin-top: $header-height;
      //
      background-color: $header-background-color;
      background: linear-gradient(0deg, $header-background-color-bottom, $header-background-color-top);
    }
    
    .sub-menu {
      //display: block;
    }
  }
}

.advanced-menu-3d .hover .menu-item-inner {
    background: none;
  
}

.animated-item {
  a {
    transform-style: preserve-3d;
    perspective: 150px;
    display: inline-block;
  }
  //width: 150px;
  //display: inline-block;
  //background-color: rgb(143, 143, 143);
  //box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
  //vertical-align: bottom;
  //font-size: 14px;
  //line-height: 1.2em;
  

  
  .menu-item-a, .menu-item-b {
    display: inline-block;
  }
  
  .menu-item-a {
    //height: 50px;
    //width: 150px;
    transform-style: preserve-3d;
    transform: translateZ(-50px) rotateX(00deg)  translateZ(50px);

    background-color: $header-background-color;
    background: linear-gradient(0deg, $header-background-color-bottom, $header-background-color-top);
    
    opacity: 1;
  }
  
  .menu-item-b {
    position: absolute;
    top: 75px;
    left: 0px;
    transform-style: preserve-3d;
    transform: translateZ(-50px) rotateX(90deg)  translateZ(50px);
    transition: transform 0.5s;
    background-color: rgb(103, 103, 103);
    color: rgb(200,200,200);
    opacity: 0;
  }
}

.lyt-main-menu {
  .lyt-secondary-menu {
    padding: 0px;
    margin: 0px;
    float: left;
    padding-top: 5px;
    margin-left: 50px;
    margin-right: 50px;
  
    li {
      display: block;
      padding: 0px;
      margin: 0px;
    }
  
    .phone-item {
      margin-top: 6px;
    }
  }
}

.lyt-header-bottom {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 6px;
  bottom: -6px;
  font-size: 0px;
  background-color: rgb(39, 39, 39);
  z-index: -1;
  transform:skewX(30deg);
  transform-origin: left top;
}

.logo-link, .logo-link:hover {
  text-decoration: none;
}

.submenu-min-width {
  width: 140px;
  height: 0px;
  font-size: 0px;
}

.lyt-secondary-menu {
  font-size: 0.8em;
}

.lyt-header-placeholder {
  height: 190px;
}

@import "_layout.content.default.scss";
@import "_gallery.scss";

/* ======== */
.lyt-logo-container-sm {
  display: none;
}


$logo-lg-width: 230px;
$logo-lg-height: 142px;
$logo-lg-ratio: $logo-lg-height/$logo-lg-width;

.lyt-logo-container-lg {
  position: absolute;
  left: 40px;
  top: -20px;
  display: inline-block;
  width: 230px;
  height: $logo-lg-ratio*230px;
  
  img {
    width: 100%;
    height: 100%;
  }
}

$logo-lg-width-smaller : 180px;



//fix the size for somaler screens
@media (max-width: 1180px) {
  .lyt-header {
    left: auto;
    padding-right: 0px;
    width: 92%;
  }
}

@media (max-width: 800px) {
  
  .lyt-logo-container-lg {
    left: 90px;
    top: -20px;
    width: $logo-lg-width-smaller;
    height: $logo-lg-ratio*$logo-lg-width-smaller;
  }
  
  .lyt-header {
    left: 20px;
    padding-right: 0px;
    width: auto;
  }
  .lyt-logo-container-lg {
    left: 60px;
  }
  
  .lyt-main-menu {
    left: 260px;
    
    .lyt-secondary-menu {
      margin-left: 10px;
    }
  }
}
