
.lty-content {
  color: white;
}

.page {
  overflow: hidden;
  min-height: 500px;
  min-height: 100vh;
  
  background-position: center center;
  background-size: cover;

  .page-inner {
    position: relative;
    left: 0px;
    top: 0px;
    
    max-width: 1000px;
    margin: auto;

    //overflow: hidden;
    //@include has-layout;
    
    h1 {
      //position: absolute;
      //left: 10px;
      //top: 20px;
      font-size: 32px;
    }
    
   
    
    .content-slogan {
      float: left;
      width: 312px;
      font-size: 16px;
      background-color: rgba(0,0,0,0.5);
      padding: 10px;
    }
    
    .content-text {
      margin-top: 20px;
      margin-left: 360px;
      font-size: 16px;
    }
  }
}

.page--start {
  position: relative;
  left: 0;
  top: 0;
  
  .page-inner {
    h1 {
      text-align: left;
      display: block;
      font-size: 70px;
      text-shadow: 2px 2px 10px rgba(0,0,0,0.8);
    }
  }
  
  .scroll-down-arrow {
    position: absolute;
    top: 350px;
    left: 0px;
    right: 0px;
    text-align: center;
  }
}