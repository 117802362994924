.lightbox {
  display: none;
}


//reset the main style
.lyt-main-menu {
  position: static;
  left: auto;
  right: auto;
  top: auto;
  

  font-weight: bold;
  
  .lty-primary-menu, .lyt-secondary-menu {
    float: none;
    margin: 0px;
    padding: 0px;
    font-size: inherit;
    
    .menu-item {
      .sub-menu {
        position: static;
        display: block;
        border: transparent;
        background: none;
      }
    }
    
    li {
      border-top: 1px solid rgba(0,0,0,0.1);
      position: static;
      float: none;
      line-height: inherit;
      
      a, .menu-item-inner {
        display: block;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        
        span {
          padding: 0px;
        }
        
        .menu-item-a {
          background: none;
        }
      }
    }
  }
}

.lty-primary-menu .menu-item .menu-item-inner:hover, .lty-primary-menu .menu-item .menu-item-inner.active {
    text-shadow: none;
}

.lyt-header-bottom, .lyt-header-bg, .lyt-header-bg-glow {
  display: none;
}

// ===== the actual sytle
.lyt-header-placeholder {
  height: 50px;
}
.lyt-logo-container-lg {
  display: none;
}

.lyt-logo-container-sm {
  position: relative;
  display: block;
  left: 75px;
  top: 3px;
}

.page--start .page-inner h1 {
  font-size: 2.4em;
}


.page-inner h1 {
  font-size: 1.8em;
  padding: 5px;
}

.page-inner {
  padding: 10px;
}



.page .page-inner {
  .content-slogan {
    float: none;
    left: auto;
    width: auto;
    padding: 10px;
    margin: 0px;
  }
  
  .content-text {
    padding: 5px;
    margin: 0px;
  }
}

.lyt-header {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 46px;
}

.lyt-header-bg {
  z-index: 5;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  background: none;
  background-color: #f1e0d0;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

.phone-mobile {
  position: absolute;
  right: 10px;
  bottom: 5px;
  display: inline-block;
  color: black;
  a {
    color: inherit;
  }
}

.menu-toggle {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #afa295;
  z-index: 10;

  margin-left: 20px;
  margin-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #f6eadd;
  .bar {
    border-radius: 1px;
    background-color: #afa295;
    height: 1px;
    border: 1px solid #afa295;
    overflow: hidden;
    width: 17px;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 6px;
    margin-right: 6px;
  }
}


.lyt-main-menu {
  display: none;
  
  .lty-primary-menu {
    padding-top: 40px;
  }
  
  .lty-primary-menu, .lyt-secondary-menu {
    margin: 10px;
  }
}



.logo-pyramide {
  position: relative;
  top: -10px;
  width: 70px;
  height: 35px;
  display: inline-block;
  background-image: url(../logo-pyramide.png);
  background-size: 100% 100%;
}

.logo-pyramide-outer {
  position: relative;
  top: 15px;
  display: inline-block;
  width: 96px;
  height: 42px;
  text-align: center;
  background-image: url(../logo-bg-small.png);
  background-size: 100% 100%;
  z-index: 6;
}

.logo-text {
  left: 0px;
  top: 0px;
  position: relative;
  display: inline-block;
  margin-left: 25px;
  width: 150px;
  height: 25px;
  background-image: url(../hartmannsgruber-fliesen.png);
  background-size: 100% 100%;
  z-index: 6;
}

.lyt-menu-small-visible {
  .lyt-main-menu {
    display: block;
  }
}

.lyt-main-menu {
  position: fixed;
  left: 0px;
  top: 45px;
  bottom: 0px;
  right: 0px;
  overflow: auto;
  background-color: lighten(rgba(#f1e0d0,0.95),5);
  z-index: 0;
}
/*
.lyt-main-menu-container {
  position: absolute;
  left: 0px;
  top: 0px;
}

.lyt-content {
  position: absolute;
  left: 0px;
  top: 0px;
}


@media (max-width: 360px) { 
  .menu-toggle {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .logo-text {
    margin-left: 10px;
  }
}

@media (max-width: 330px) { 
  .menu-toggle {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .logo-text {
    margin-left: 5px;
  }
}*/