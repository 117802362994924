
/**** ==== ***/


.gallery-image {
  cursor: zoom-in;
}
.wrapper-image {
  cursor: zoom-out;
}

/*.no-scroll, .no-scroll body {
  overflow: hidden;
}*/

.gallery-preview {
  width: 761px;
  height: 281px;
  position: relative;
  left: 0px;
  top: 0px;
}


.lightbox {
  .controlls {
    position: absolute;
    left: 10px;
    top: 10px;
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 2;
  }

  .controlls:hover {
    opacity: 1;
  }

  * {
    box-sizing : content-box;
  }
  
  ul li {
    position: relative;
    border: 5px solid rgba(255,255,255,0.5);
    display: inline-block;
    line-height: 0;
    margin-left: 10px;
    margin-right: 10px;
  
    transition:  0.3s  transform;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }



  ul li:nth-child(6n+1) {
    transform: rotateZ(-10deg);
  }

  ul li:nth-child(6n+2) {
    transform: rotateZ(-20deg);
  }

  ul li:nth-child(6n+3) {
    transform: rotateZ(-15deg);
  }

  ul li:nth-child(6n+4) {
    transform: rotateZ(-30deg);
  }

  ul li:nth-child(6n+5) {
    transform: rotateZ(-17deg);
  }

  ul li:hover {
    transform: rotateZ(0deg) scale(1.5);
    z-index: 10;
  }

  .gallery-row {
    margin-top: -20px;
  }

  .gallery-bg1 {
    background-image: url(/galleries/background/bg1.png);
    background-repeat: repeat-x;
    overflow: hidden;
  }

  .gallery-bg2 {
    background-image: url(/galleries/background/bg2.png);
    background-repeat: repeat-x;
  }

  .gallery-bg3 {
    background-image: url(/galleries/background/bg3.png);
    background-repeat: repeat-x;
  }
  
  .image {
    position: relative;
    left: 0px;
    top: 0px;
    border: 10px solid rgba(0,0,0,0.5);
    width: 761px;
    height: 281px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
  }

  .images {
    position: relative;
  }

  .gallery-controll {
    cursor: pointer;
  }

  

  .page-indicators {
    text-align: center;
  }

  .page-indicators .page-indicator {
    margin: 5px;
    color: white;
    cursor: pointer;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }


  .gallery-controll:hover {
    color: rgb(120,120,255);
  }
  
  .page-indicators .page-indicator-active {
    color: rgb(120,120,255);
  }
  
  ul li.active {
    border-color: rgb(120,120,255);
  }
  
}


