@import "vendors/_normalize";
@import "vendors/bourbon/_bourbon";
@import "vendors/bootstrap-sass-3.3.5/assets/stylesheets/_bootstrap";
@import "vendors/font-awesome-4.4.0/scss/font-awesome.scss";


body, html {
  font-family: Sans-Serif;
  width: 100%;
  height: 100%;
  hyphens: auto;
  
}

html {
  overflow-y: scroll;
}






$header-height: 80px;
$content-max-width: 1000px;
$header-bottom-height: 6px;
$color-front-bg: #7b7b7b;//#7d7063;
$header-top-offset : 100px;
$header-top-fixed: 20px;

$content-max-width: 1000px;

#ewv-widget {
  z-index: 10;
}

@import "_layout.default";

@media (max-width: 689px) { 
  @import "_layout.mobil";
}


.start-animation {
  .page--start {
    .page-inner {
  
      h1, .content-container {
        border: 1px solid red;
        display: none;
      }
    }
  }
}


.qr-container:hover {
  opacity: 1;
}

.qr-container {
  font-size: 10px;
  border: 1px solid black;
  border-radius: 4px;
  width: 40px;
  background-color: rgb(255,255,255);
  overflow: hidden;
  opacity: 0.3;
  
  .qr-title {
    text-align: center;
    height: 0px;
    overflow: hidden;
  }
  
  .qr-code {
    width : 40px;
    height: 30px;
    overflow: hidden;
    
    .inner {
      background-color: white;
    }
  }
  
}

.qr-container-outer {
  position: absolute;
  left: 30px;
  top: 10px;
}

//@import "_debug";
//@import "_layout";
//
//.page {
//  color: white;
//  a {
//    color: inherit;
//    text-decoration: underline;
//  }
//  
//  h1 {
//    font-size: 1.3em;
//    font-weight: bold;
//  }
//  
//  h2 {
//    font-size: 1.1em;
//    font-weight: bold;
//  }
//  
//  .content-text, .content-slogan, p {
//    line-height: 1.3em;
//    text-align: justify;
//  }
//}
//
//
//
//@import "_gallery";



